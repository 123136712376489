.cost-center-trial-balance {
  overflow: hidden;
  overflow-x: scroll;
  max-height: 60vh;

  @media (min-height: 768px) {
    max-height: 73vh;
  }

  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(251, 251, 251) !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 10px !important;
    display: inline;
    unicode-bidi: bidi-override;
    margin-left: 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgb(237, 243, 248);
  }

  &::-webkit-scrollbar-thumb {
    background: #e0e9f6 !important;
    border-radius: 10px !important;
    margin-left: 20px !important;
  }

  table > :not(caption) > * > * {
    padding: 0.4rem !important;
  }

  .table-heading {
    th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: rgb(17, 17, 17);
      color: white !important;
    }

    tr {
      background-color: #edf3f8;

      .single-column-data {
        min-width: 200px;
      }

      .single-column-ledger {
        min-width: 18rem;
      }

      .single-column-amount {
        min-width: 15rem;
      }
    }
  }
}

.table-profit-loss {
  table {
    border: 1px dashed grey !important;
    width: 500px;

    tr {
      background-color: #f8f8f8;
    }
  }
}

.sidebar-sticky-table {
  thead {
    z-index: 999 !important;
  }

  .sidebar-sticky {
    position: sticky !important;
    left: 0 !important;
    z-index: 999 !important;
  }

  .sidebar-sticky-with-bg {
    position: sticky !important;
    left: 0 !important;
    z-index: 1 !important;
    background-color: white !important;
  }

  .table-sticky-content {
    position: relative;
    z-index: -1 !important;
  }

  .sidebar-sticky-with-out-bg {
    position: sticky !important;
    left: 0 !important;
    z-index: 1 !important;
    background-color: #cde2ff24 !important;
  }

  .sidebar-sticky-total {
    position: sticky !important;
    left: 0 !important;
    z-index: 1 !important;
    background-color: #ededed !important;
  }
}
