.notification-settings {
  height: 83vh;
  max-height: 83vh;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  .main-table {
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      background-color: aliceblue;
      z-index: 999;
      font-size: 13px;
      font-weight: 600;

      color: #000000cd;
      th {
        .clone-button {
          outline: none;
          border: none;
          background-color: #fff;
          padding: 4px 15px;
          color: #5783aa;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
            rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        }
        .save-button{
          border: none;
          background-color: #fff;
          padding: 4px 15px;
          color: #236c2af1;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
            rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        }
        padding: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .notification-type-heading {
        font-size: 14px;
      }
    }
    .accordion {
      .accordion-item {
        border-bottom: none !important;
        .accordion-header {
          button {
            background-color: rgb(231 231 231 / 33%) !important;
            font-size: 14px !important;
            color: #000 !important;
            font-weight: 500 !important;
            box-shadow: none !important;
            outline: none !important;
            text-transform: capitalize;
          }
        }
        .accordion-body {
          // background-color: #ffe4c40f;
          table {
            td {
              .role-data {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #eeeeee;
                width: max-content;
                font-size: 10px;
                border-radius: 9px;
                padding: 2px 13px;
                .delete {
                  cursor: pointer;
                  color: #000000b7;
                  font-size: 12px;
                  margin-left: 5px;
                }

                .delete {
                  font-size: 10px;
                }
                .delete:hover {
                  color: #ff0707;
                  font-size: 12px;
                }
              }
              // border: 3px solid #f7f7f7;
              color: #000000b7 !important;
              .form-check-input {
                box-shadow: none !important;
                cursor: pointer;
              }
              .form-check-input[type='switch'] {
                height: 13px;
                width: 25px;
                box-shadow: none;
                outline: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
