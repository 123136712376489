.card-loader-container {
  .card-loader-wrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(#000, 0.15);
    overflow: hidden;
    border: dashed 1px #dadada;

    .card-loader-img {
      padding-bottom: 46.5%;
      position: relative;
      img {
        position: absolute;
        width: 100%;
      }
    }

    .card-loader-body {
      padding: 1rem;

      .card-loader-title {
        line-height: 1.33;
        &.loader-skeleton {
          min-height: 27px;
          border-radius: 4px;
        }
      }

      .card-loader-intro {
        line-height: 1.5;
        &.loader-skeleton {
          min-height: 44.5px;
          border-radius: 4px;
        }
      }
    }

    .loader-skeleton {
      background-color: #e2e5e7;
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0),
        rgba(#fff, 0.5),
        rgba(#fff, 0)
      );
      background-size: 40px 100%;
      background-repeat: no-repeat;
      background-position: left -40px top 0;
      animation: shine 1s ease infinite;
    }
  }

  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
}
